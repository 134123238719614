import React, { useState, Fragment } from "react";
import "./App.css";
import "react-image-lightbox/style.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Header from "components/Header";
import Footer from "components/Footer";
import DashboardComponent from "components/Dashboard";
import Home from "pages/Home";
import Registration from "pages/Registration";
// import GetFunded from "pages/GetFunded";
// import HowItWorks from "pages/HowItWorks";
import HowItWorks from "redesign/HowItWorks";
import Verify from "pages/Verify";
import Activate from "pages/Activate";
import Forget from "pages/Forget";

import Dashboard from "pages/Dashboard/Dashboard";
import BankAccount from "pages/Dashboard/BankAccount";
import CampaignUpdates from "pages/Dashboard/CampaignUpdates";
import CampaignUpdate from "pages/Dashboard/CampaignUpdate";
import Portfolio from "pages/Dashboard/Portfolio";
import PortfolioThankyou from "pages/Dashboard/PortfolioThankyou";
import Portfolios from "pages/Dashboard/Portfolios";
import Profile from "pages/Dashboard/Profile";
import Project from "pages/Dashboard/Project";
import KBWallet from "pages/Dashboard/KBWallet";
import Pin from "pages/Dashboard/Pin";

import Campaigns from "pages/Campaign/Campaigns";
import Campaign from "pages/Campaign/Campaign";

import Media from "pages/Media";
import Career from "pages/Career";
// import Contact from "pages/Contact";
// import Legal from "pages/Legal";
// import Faq from "pages/Faq";
// import Blogs from "pages/Blog/Blogs";
import Blog from "pages/Blog/Blog";
import BasisID from "pages/BasisID";
import Veriff from "pages/Veriff";

// redesign
import About from "redesign/About";
import Faq from "redesign/Faq";
import GetFunded from "redesign/GetFunded";
import MekariCallback from "MekariEsign/Callback";
import Legal from "redesign/Legal";
import Referal from "redesign/Referal";
import Contact from "redesign/Contact";
import Blogs from "redesign/Blogs";

import Referral from "pages/Dashboard/Referral";

import ReactPixel from "react-facebook-pixel";
import {
  LoginDialogProvider,
  useLoginDialog,
} from "contexts/LoginDialogContext";
import LoginDialog from "components/LoginDialog";
import ForgetPasswordDialog from "components/ForgetPasswordDialog";
import ResendEmailDialog from "components/ResendEmailDialog";
import { ForgetPasswordDialogProvider } from "contexts/ForgetPasswordDialogContext";
import { ResendEmailDialogProvider } from "contexts/ResendEmailDialogContext";
import { RegisterSuccessDialogProvider } from "contexts/RegisterSuccessDialogContext";
import RegisterSuccessDialog from "components/RegisterSuccessDialog";
import { LogoutDialogProvider } from "contexts/LogoutDialogContext";
import LogoutDialog from "components/LogoutDialog";
import Login from "pages/Login";
import ScrollToTop from "components/ScrollToTop";

// ReactPixel.init('3152294181724448'); // acoubaint
ReactPixel.init("203885370040865"); // KB
ReactPixel.pageView();

function PrivateRoute({ children, is_dashboard, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const params = new URLSearchParams(location.search);
        return localStorage.getItem("token") || params.get("a") ? (
          <Fragment>
            {is_dashboard ? (
              <Grid container>
                <Hidden only={['xs', 'sm']}>
                  <Grid item md={2}>
                    <DashboardComponent {...rest} />
                  </Grid>
                </Hidden>
                <Grid item xs={12} md={10}>
                  {children}
                </Grid>
              </Grid>
            ) : (
              // <DashboardComponent >
              //    {children}
              // </DashboardComponent>
              children
            )}
          </Fragment>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

function AuthRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return !localStorage.getItem("token") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

const headingDefault = {
  fontFamily: "Raleway",
  fontWeight: 700,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
    heading1: {
      ...headingDefault,
      fontSize: 48,
    },
    heading2: {
      ...headingDefault,
      fontSize: 40,
    },
    heading3: {
      ...headingDefault,
      fontSize: 32,
    },
    heading4: {
      ...headingDefault,
      fontSize: 25,
    },
    heading5: {
      ...headingDefault,
      fontSize: 20,
    },
    heading6: {
      ...headingDefault,
      fontSize: 16,
    },
    heading7: {
      ...headingDefault,
      fontSize: 14,
    },
    heading8: {
      ...headingDefault,
      fontSize: 10,
    },
    heading9: {
      ...headingDefault,
      fontSize: 8,
    },
    body2: {
      fontWeight: 400,
      fontSize: 16,
    },
    body3: {
      fontWeight: 400,
      fontSize: 16,
    },
    bodyFooter1: {
      fontSize: 16,
      fontWeight: 300,
    },
  },
  palette: {
    primary: {
      main: "#1eb2f6",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#335f8c",
      contrastText: "#ffffff",
    },
    gray2: {
      main: "#4F4F4F",
    },
    gray4: {
      main: "#BDBDBD",
    },
    gray5: {
      main: "#E0E0E0",
    },
    gray6: {
      main: "#F2F2F2",
    },
    ceremainBlue: {
      main: "#0C74DD",
    },
    darkBlue: {
      main: "#03045E",
    },
    darkLightBlue: {
      main: "#023E8A",
    },
    darkLightBlue2: {
      main: "#0077B6",
    },
    sunshine: {
      main: "#F9AB2E",
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ScrollToTop />

        <ResendEmailDialogProvider>
          <ForgetPasswordDialogProvider>
            <LoginDialogProvider>
              <LogoutDialogProvider>
                <RegisterSuccessDialogProvider>
                  <AnimationApp />
                </RegisterSuccessDialogProvider>
              </LogoutDialogProvider>
            </LoginDialogProvider>
          </ForgetPasswordDialogProvider>
        </ResendEmailDialogProvider>
      </ThemeProvider>
    </Router>
  );
}

function AnimationApp() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const {
    isOpen: isOpenLoginDialog,
    onOpen: onOpenLoginDialog,
    onClose: onCloseLoginDialog,
  } = useLoginDialog();
  const [open_logout_dialog, setOpenLogoutDialog] = useState(false);

  const onLogoutDialog = (e) => {
    e.preventDefault();
    setOpenLogoutDialog(true);
  };
  const onCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  // let {state} = location
  // if(state && state.from) {
  //    setOpenLoginDialog(true)
  //    location.state = null
  // }
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf2vN4UAAAAAI_q8fmljTPB_WlNe3PZlaZQozg7">
      <div style={{ marginTop: matches ? 60 : 110 }}>
        <Header />
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Switch location={location}>
              <AuthRoute path="/login">
                <Login location={location} />
              </AuthRoute>
              <AuthRoute path="/registration">
                <Registration location={location} />
              </AuthRoute>
              <Route path="/esign/callback">
                <MekariCallback location={location} />
              </Route>
              <Route path="/about">
                <About location={location} />
              </Route>
              <Route path="/get-funded">
                <GetFunded location={location} />
              </Route>
              <Route path="/how-it-works">
                <HowItWorks location={location} />
              </Route>
              <Route path="/media">
                <Media location={location} />
              </Route>
              <Route path="/career">
                <Career location={location} />
              </Route>
              <Route path="/contact-us">
                <Contact location={location} />
              </Route>
              <Route path="/legal">
                <Legal location={location} />
              </Route>
              <Route path="/faq">
                <Faq location={location} />
              </Route>
              <Route path="/blog/:slug">
                <Blog location={location} />
              </Route>
              <Route exact path="/blog">
                <Blogs location={location} />
              </Route>
              <Route path="/basis-id">
                <BasisID location={location} />
              </Route>
              <Route path="/veriff">
                <Veriff location={location} />
              </Route>
              <Route exact path="/referral">
                <Referal location={location} />
              </Route>
              <Route
                path="/verify/:code"
                component={(props) => (
                  <Verify
                    {...props}
                    open_login_dialog={isOpenLoginDialog}
                    onLoginDialog={onOpenLoginDialog}
                    onCloseLoginDialog={onCloseLoginDialog}
                  />
                )}
              />
              <Route
                path="/activate/:code"
                component={(props) => (
                  <Activate
                    {...props}
                    open_login_dialog={isOpenLoginDialog}
                    onLoginDialog={onOpenLoginDialog}
                    onCloseLoginDialog={onCloseLoginDialog}
                  />
                )}
              />
              <Route
                path="/forget/:code"
                component={(props) => (
                  <Forget
                    {...props}
                    open_login_dialog={isOpenLoginDialog}
                    onLoginDialog={onOpenLoginDialog}
                    onCloseLoginDialog={onCloseLoginDialog}
                  />
                )}
              />

              <Route exact path="/campaign">
                <Campaigns
                  location={location}
                  onLoginDialog={onOpenLoginDialog}
                />
              </Route>
              <PrivateRoute path="/campaign/:slug">
                <Campaign location={location} />
              </PrivateRoute>

              <PrivateRoute
                exact
                path="/dashboard"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <Dashboard location={location} />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/dashboard/portfolio"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <Portfolios location={location} />
              </PrivateRoute>
              <PrivateRoute
                path="/dashboard/portfolio/:id"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <Portfolio location={location} />
              </PrivateRoute>
              <PrivateRoute
                path="/dashboard/portfolio-thankyou/:id"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <PortfolioThankyou location={location} />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/dashboard/campaign-update"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <CampaignUpdates location={location} />
              </PrivateRoute>
              <PrivateRoute
                path="/dashboard/campaign-update/:id"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <CampaignUpdate location={location} />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/dashboard/profile"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <Profile location={location} />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/dashboard/bank-account"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <BankAccount location={location} />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/dashboard/project"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <Project location={location} />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/dashboard/referral"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <Referral location={location} />
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/dashboard/kb-wallet"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <KBWallet location={location} />
              </PrivateRoute>

              <PrivateRoute
                exact
                path="/dashboard/pin"
                is_dashboard
                onLogoutDialog={onLogoutDialog}
              >
                <Pin location={location} />
              </PrivateRoute>

              <Route exact path="/">
                <Home />
              </Route>
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <Footer />

        <LoginDialog />
        <LogoutDialog />
        <ForgetPasswordDialog />
        <ResendEmailDialog />
        <RegisterSuccessDialog />
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
