import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Paper,
  NativeSelect,
  Snackbar,
  Divider,
  InputAdornment,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AccountCircle, Close, PictureAsPdfOutlined } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import api from "services/api";
import hp from "services/hp";

class Page extends Component {
  state = {
    profile: null,
    openSuccessSnack: false,
    save_loading: false,
    nric_file: null,
    nric_file_back: null,
    address_proof: null,
    is_approved: false,
  };

  componentDidMount() {
    this.onStart();
  }

  openBasisIdTab = () => {
    this.props.changeTab(null, 2)
  }
  
  onStart = async () => {
    const profile_res = await api.get("profile");
    const profile = profile_res.data;
    
    if (profile.status == "approved") {
      this.setState({ is_approved: true });
    }

    hp.save_user(profile);
    let countries_res = await api.get('misc/country');
    let countries = countries_res.data;
    
    this.setState({ countries, profile });
  };

  onChange = (e, f) => {
    let { profile } = this.state;
    profile[f] = e.target.value;
    this.setState({ profile });
  };
  onChangeDOB = (date) => {
    let { profile } = this.state;
    profile.dob = format(new Date(date), "yyyy-MM-dd");
    this.setState({ profile });
  };
  onAttach = (e, f) => {
    let { state } = this;
    state[f] = e.target.files[0];
    this.setState({ state });
  };

  onSave = async (e) => {
    e.preventDefault();
    const { profile, nric_file, nric_file_back, address_proof } = this.state;
    if (!profile.dob) {
      alert("Please fill in your Date of Birth");
      return;
    }

    let formData = new FormData();
    // disable validation
    // if (!nric_file && !profile.nric_file) {
    //   alert("Please upload your Identification Card/Passport (front)");
    //   return;
    // }
    // if (nric_file) {
    //   formData.append("nric_file", nric_file);
    // }
    // if (nric_file_back) {
    //   formData.append("nric_file_back", nric_file_back);
    // }
    if (!address_proof && !profile.address_proof) {
      alert("Please upload your Proof of Address");
      return;
    }
    if (address_proof) {
      formData.append("address_proof", address_proof);
    }
    this.setState({ save_loading: true });
    let required = [
      "country",
      "nationality",
      "dob",
      "firstname",
      "lastname",
      "phone_no",
      "address",
      "nric",
      "ic_type",
      "ic_name",
      "ic_country",
    ];
    required.forEach((require, i) => {
      formData.append(require, profile[require]);
    });
    try {
      const res = await api.post("profile", formData);
      this.setState({ save_loading: false });

      let { data } = res;

      if (data.success == 0) {
        alert(data.message);
        return false;
      }

      window.scrollTo(0, 0);
      this.setState({
        nric_file: null,
        nric_file_back: null,
        address_proof: null,
        openSuccessSnack: true,
        profile: data,
      });
      hp.save_user(data);

      if(profile.veriff == null) {
        this.openBasisIdTab();
      }

    } catch (e) {
      console.log(e)
      alert(
        "Failed to update your profile. Please ensure the data entered is correct."
      );
      // window.location.reload();
      this.setState({ save_loading: false });
    }
  };

  render() {
    const {
      profile,
      countries,
      nric_file,
      nric_file_back,
      address_proof,
      save_loading,
      is_approved,
    } = this.state;
    return (
      <div id="profile">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Container maxWidth="xl">
            {profile ? (
              <Fragment>
                <div className="spaceBottom">
                  {profile.status == "new" ? (
                    <Alert severity="warning">
                      As part of our Know Your Customer (KYC) procedure, please
                      complete your profile and upload identification documents
                      before proceeding to invest.
                    </Alert>
                  ) : profile.status == "reviewing" ? (
                    <Alert severity="info">
                      Your KYC documents are successfully submitted and your
                      account is currently under review. This may take up to 3
                      working days.
                    </Alert>
                  ) : profile.status == "approved" ? (
                    <Alert severity="success">
                      Your account has been approved for investment.
                    </Alert>
                  ) : profile.status == "blacklist" ? (
                    <Alert severity="error">
                      Your account has been put under blacklist. Please contact
                      us if you think this was a mistake.
                    </Alert>
                  ) : profile.status == "rejected" ? (
                    <Alert severity="error">
                      Your submission has been rejected. Please submit with
                      correct information.
                    </Alert>
                  ) : (
                    <Alert severity="error">no status</Alert>
                  )}
                </div>
                
                <form onSubmit={this.onSave}>
                  <Paper className="spaceBottom" style={{ paddingBottom: 30 }}>
                    <Typography variant="h5" className="bold spaceBottom">
                      Personal Profile
                    </Typography>
                    <Grid container spacing={5}>
                      <Grid item xs={12} md={6}>
                        <div className="padding">
                          <FormControl fullWidth margin="normal" required>
                            <InputLabel>Country of Residence</InputLabel>
                            <NativeSelect
                              value={profile.country}
                              onChange={(e) => this.onChange(e, "country")}
                            >
                              {countries &&
                                countries.map((country, i) => (
                                  // <MenuItem key={i} value={country.name} >{country.name}</MenuItem>
                                  <option key={i} value={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                            </NativeSelect>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="padding">
                          <FormControl fullWidth margin="normal" required>
                            <InputLabel>Nationality</InputLabel>
                            <NativeSelect
                              value={
                                profile.nationality ? profile.nationality : ""
                              }
                              onChange={(e) => this.onChange(e, "nationality")}
                            >
                              {countries &&
                                countries.map((country, i) => (
                                  <option key={i} value={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                            </NativeSelect>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="padding">
                          <DatePicker
                            disableFuture
                            format="dd MMMM yyyy"
                            margin="normal"
                            openTo="year"
                            id="date-picker-inline"
                            label="Date of Birth"
                            value={profile.dob ? new Date(profile.dob) : null}
                            onChange={(e) => this.onChangeDOB(e)}
                            // KeyboardButtonProps={{
                            //   "aria-label": "change date",
                            // }}
                            fullWidth
                            required
                          />
                        </div>
                      </Grid>
                      {[
                        ["First Name", "firstname"],
                        ["Handphone No.", "phone_no"],
                        ["Last Name", "lastname"],
                        ["Residential Address", "address"],
                      ].map((item, i) => (
                        <Grid item xs={12} md={6} key={i}>
                          <div className="padding">
                            <TextField
                              margin="normal"
                              label={item[0]}
                              type="text"
                              fullWidth
                              required
                              value={profile[item[1]]}
                              onChange={(e) => this.onChange(e, item[1])}
                            />
                          </div>
                        </Grid>
                      ))}
                      <Grid item xs={12} md={6}>
                        <div className="padding">
                          <TextField
                            margin="normal"
                            label="Email"
                            type="text"
                            fullWidth
                            required
                            value={profile.email}
                            InputProps={{ readOnly: true }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4}>
                      <div className="padding">
                        <Typography className="bold space">
                          Proof of Address{" "}
                        </Typography>
                        <Typography>
                          (Bank statement, phone/utility bill or government
                          issued letter dated no more than 3 months old.){" "}
                          {/* (Bank statement, utility bill or government issued
                          letter dated no more than 3 months old. Please note
                          that telco bills and insurance letters are not
                          accepted.){" "} */}
                        </Typography>
                        <Typography className="dim">
                          Image format only and max size is 1mb
                        </Typography>
                        {profile.address_proof && (
                          <img src={profile.address_proof} width="100%" /> 
                        )}
                        <input
                          accept="image/*"
                          id="profile-address-proof"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => this.onAttach(e, "address_proof")}
                        />
                        {is_approved ? (
                          <label htmlFor="profile-address-proof">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                              disabled
                            >
                              Browse
                            </Button>
                          </label>
                        ) : (
                          <label htmlFor="profile-address-proof">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                            >
                              Browse
                            </Button>
                            {address_proof && (
                              <Typography>
                                Attached: {address_proof.name}
                              </Typography>
                            )}
                          </label>
                        )}
                        {/* {address_proof && (
                          <Typography>
                            Attached: {address_proof.name}
                          </Typography>
                        )} */}
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className="spaceBottom" style={{ paddingBottom: 30 }}>
                    <Typography variant="h5" className="bold spaceBottom">
                      Personal Identification Information
                    </Typography>
                    <Grid container spacing={5}>
                      <Grid item xs={12} md={6}>
                        <div className="padding">
                          <TextField
                            margin="normal"
                            label="NRIC/Passport No"
                            type="text"
                            fullWidth
                            required
                            value={profile.nric}
                            onChange={(e) => this.onChange(e, "nric")}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="padding">
                          <FormControl fullWidth margin="normal" required>
                            <InputLabel>
                              Type of Identification Number
                            </InputLabel>
                            <Select
                              value={profile.ic_type ? profile.ic_type : ""}
                              onChange={(e) => this.onChange(e, "ic_type")}
                            >
                              <MenuItem value="Identity Card">
                                Identity Card
                              </MenuItem>
                              <MenuItem value="FIN">FIN</MenuItem>
                              <MenuItem value="Passport">Passport</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="padding">
                          <TextField
                            margin="normal"
                            label="Full name as shown in Passport / ID"
                            type="text"
                            fullWidth
                            required
                            value={profile.ic_name}
                            onChange={(e) => this.onChange(e, "ic_name")}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="padding">
                          <FormControl fullWidth margin="normal" required>
                            <InputLabel>Issuing country</InputLabel>
                            <Select
                              value={
                                profile.ic_country ? profile.ic_country : ""
                              }
                              onChange={(e) => this.onChange(e, "ic_country")}
                            >
                              {countries &&
                                countries.map((country, i) => (
                                  <MenuItem key={i} value={country.name}>
                                    {country.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className="spaceBottom" style={{ paddingBottom: 30, display: 'none' }}>
                    <Typography variant="h5" className="bold spaceBottomX">
                      Upload Proof of Identification
                    </Typography>
                    <Grid container spacing={5}>
                      <Grid item xs={12} md={4}>
                        <Typography className="bold space">
                          Identification Card / Passport (front)
                        </Typography>
                        <Typography>
                          (For Singapore-based investors, please use NRIC, FIN
                          or 11B only.)
                        </Typography>
                        <Typography className="dim">
                          *.jpg and *.pdf formats only{" "}
                        </Typography>
                        {profile.nric_file && (
                          <img src={profile.nric_file} width="100%" />
                        )}
                        <br />
                        {/* <Typography className="bold space">
                          Please upload in the next step after clicking Submit
                        </Typography> */}
                        {/* <input
                          accept="image/*"
                          id="profile-nric-file"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => this.onAttach(e, "nric_file")}
                        /> */}
                        {is_approved ? (
                          <label htmlFor="profile-nric-file">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                              disabled
                            >
                              Browse
                            </Button>
                          </label>
                        ) : (
                          <label htmlFor="profile-nric-file">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                            >
                              Browse
                            </Button>
                            {nric_file && (
                              <Typography>
                                Attached: {nric_file.name}
                              </Typography>
                            )}
                          </label>
                        )}
                        
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className="bold space">
                          Identification Card (back)
                        </Typography>
                        <Typography>
                          (For Singapore-based investors, please use NRIC, FIN
                          or 11B only.)
                        </Typography>
                        <Typography className="dim">
                          *.jpg and *.pdf formats only{" "}
                        </Typography>
                        {profile.nric_file_back && (
                          <img src={profile.nric_file_back} width="100%" />
                        )}
                        <br />
                        {/* <Typography className="bold space">
                          Please upload in the next step after clicking Submit
                        </Typography> */}
                        {/* <input
                          accept="image/*"
                          id="profile-nric-file-back"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => this.onAttach(e, "nric_file_back")}
                        /> */}
                        {is_approved ? (
                          <label htmlFor="profile-nric-file-back">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                              disabled
                            >
                              Browse
                            </Button>
                          </label>
                        ) : (
                          <label htmlFor="profile-nric-file-back">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                            >
                              Browse
                            </Button>
                            {nric_file_back && (
                              <Typography>
                                Attached: {nric_file_back.name}
                              </Typography>
                            )}
                          </label>
                        )}
                        {/* {nric_file_back && (
                          <Typography>
                            Attached: {nric_file_back.name}
                          </Typography>
                        )} */}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className="bold space">
                          Proof of Address{" "}
                        </Typography>
                        <Typography>
                          (Bank statement, phone/utility bill or government
                          issued letter dated no more than 3 months old.){" "}
                          {/* (Bank statement, utility bill or government issued
                          letter dated no more than 3 months old. Please note
                          that telco bills and insurance letters are not
                          accepted.){" "} */}
                        </Typography>
                        <Typography className="dim">
                          *.jpg and *.pdf formats only{" "}
                        </Typography>
                        {profile.address_proof && (
                          <img src={profile.address_proof} width="100%" />
                        )}
                        {/* <input
                          accept="image/*"
                          id="profile-address-proof"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => this.onAttach(e, "address_proof")}
                        /> */}
                        {is_approved ? (
                          <label htmlFor="profile-address-proof">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                              disabled
                            >
                              Browse
                            </Button>
                          </label>
                        ) : (
                          <label htmlFor="profile-address-proof">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                            >
                              Browse
                            </Button>
                            {address_proof && (
                              <Typography>
                                Attached: {address_proof.name}
                              </Typography>
                            )}
                          </label>
                        )}
                        {/* {address_proof && (
                          <Typography>
                            Attached: {address_proof.name}
                          </Typography>
                        )} */}
                      </Grid>
                    </Grid>
                    <Typography variant="h6" style={{ paddingTop: 0 }}>
                      If you have any questions regarding your Profile, please
                      contact us at{" "}
                      <a href="mailto:hello@kapitalboost.com">
                        admin@kapitalboost.com
                      </a>
                    </Typography>
                  </Paper>

                  {/* <Paper className="spaceBottom" style={{ paddingBottom: 30 }} >
                  <Typography variant="h5" className="bold spaceBottom" >Additional Information</Typography>
                  <Grid container spacing={5}>
                  <Grid item xs={12} md={6}>
                  <Typography>Which of these financial products have you invested in?</Typography>

                </Grid>
              </Grid>
            </Paper> */}

                  <Paper className="mb-2">
                    {save_loading ? (
                      <Button
                        margin="normal"
                        variant="contained"
                        color="primary"
                        className="space"
                      >
                        <CircularProgress color="default" size={25} />
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ alignSelf: "flex-end" }}
                        disabled={profile.status === "approved"}
                      >
                        Submit
                      </Button>
                    )}
                  </Paper>

                  <Typography variant="h6" style={{ paddingTop: 0 }}>
                      If you have any questions regarding your Profile, please
                      contact us at{" "}
                      <a href="mailto:hello@kapitalboost.com">
                        admin@kapitalboost.com
                      </a>
                    </Typography>

                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={this.state.openSuccessSnack}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ openSuccessSnack: false })}
                  >
                    <div className="success-alert">
                      <Typography>
                        Your profile is successfully updated
                      </Typography>
                    </div>
                  </Snackbar>
                </form>
              </Fragment>
            ) : (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
          </Container>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default Page;
